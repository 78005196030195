.orderError{
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img{
        max-height: 600px;
    }
}