.marquee-container{
    overflow:hidden;
}
$count: 0;

@while $count < 5 {
    .Tilt-inner:nth-child(#{$count}) {
        left: (100 * $count) + px;
    }

    $count: $count + 1;
}

.Tilt-inner{
    position: relative;
}