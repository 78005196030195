:root {
  --slider-height-percentage: '30%';
  --control-button-background: transparent;
  --content-background-color: transparent;
}



.awssld__content>img{
  object-fit: contain;

}

.slider{
  --slider-transition-duration: 575ms;

  & .awssld__content{
    background-color: #fdfdfd;
  }
}
