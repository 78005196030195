.Footer {
    display: grid;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 40vh;
    max-width: 100vw;
    grid-row-gap: 1em;
    margin: 100px 0 0 0;
}
.Footer .footerinnerdiv {
    display: grid;
    width: 95%;
    min-height: 400px;
    justify-self: center;
    align-self: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-content: center;
    font-family: "Lato", sans-serif;
}
.Footer .footerinnerdiv h1 {
    text-shadow: 1px 1px 1px black;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}
.Footer .footerinnerdiv ul {
    padding: 0;
    list-style: none;
    display: grid;
}
.Footer .footerinnerdiv ul li {
    width: 300px;
    color: white;
    font-family: "Roboto", sans-serif;
    margin-top: 1em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0.5px 0.5px 1px black;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
}
.Footer .footerinnerdiv ul li a {
    text-decoration: none;
    color: white;
}
.Footer .footerinnerdiv .SocialMediaLinks ul li a {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
}
.Footer .footerinnerdiv ul li svg,
.Footer .footerinnerdiv ul li img {
    width: 30px;
    height: 30px;
}
.Footer .footerinnerdiv ul li span {
    align-self: center;
}
.footercopyrightbox {
    height: 75px;
    width: 95%;
    display: grid;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid white;
}
.footercopyrightbox .copyrightmark {
    justify-self: end;
    align-self: center;
    color: white;
    font-size: 11px;
    width: 275px;
    display: grid;
}
@media only screen and (min-width: 500px) and (max-width: 1175px) {
    .Footer {
        display: grid;
        height: unset;
        padding: 30px;
    }
    .Footer .footerinnerdiv {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 50px;
        padding-left: 10%;
    }
    .Footer .footerinnerdiv h1 {
        width: unset;
    }
    .Footer .footerinnerdiv ul li {
        width: 100%;
    }
}
@media only screen and (min-width: 0px) and (max-width: 500px) {
    .Footer {
        display: grid;
        height: unset;
        padding: 10px;
    }
    .Footer .footerinnerdiv {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 50px;
    }
    .Footer .footerinnerdiv h1 {
        width: unset;

        font-size: 16px;
    }
    .Footer .footerinnerdiv ul li {
        width: 100%;
        display: flex;
        font-size: 12px;
    }
    .footercopyrightbox .copyrightmark {
        justify-self: center;
    }
}
