.orderProductCard {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 8px;

    .productImageSection {
        width: 100px;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }

    .productDetailSection {
        display: grid;
        row-gap: 8px;
        padding: 16px;
    }
}

@media only screen and (max-width: 500px) {
    .orderProductCard {
        grid-column-gap: 10px;
        align-items:center;
        padding: 8px;

        .productImageSection {
            width: 44px;
            height: 44px;
          
        }

        .productDetailSection {
            padding: 8px;
        }
    }
}
